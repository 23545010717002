.menu-top {
  background-color: #96a4d2;
  z-index: 1071;
  position: fixed;
  width: 100%;
  top:0;
  max-height:120px;
}

.left-menu-container {
  margin-top: 56px;
}

.sp-container {
  position: relative;
  top: 120px;
  min-height: 100vh;  
  margin-top:5px;
  margin-left:260px !important;
  padding: 40px;
}

.sp-container-2 {
  position: relative;  
  top: 120px;
  min-height: 100vh;  
  margin-top:5px;
  padding: 40px;
}

footer {
  margin-top: 140px;
  background-color: #77b15f;
  padding-top: 40px;
  padding-bottom: 40px;  
  color: #ffffff;
  z-index: 1101;
  position: relative;
}

.foot-link {
  color: #ffffff;
  text-decoration: none;
}

header {
  background-color: #88b060;
  color: #ffffff;
  margin-bottom :10px;
  padding: 10px;
}

header a {
  color: #ffffff;
}

header a:hover {
  color: #ffffff;
}

header.sp-head {
  margin-top: -19px;
  margin-left: -20px;
  margin-right: -20px;
}

header.sticky-top {
  z-index: 1081 !important;
  top: 126px;
  border-top: 4px solid white;
  padding-top: 10px;
}

.big-spinner {
  margin-top: 200px;
  width: 10rem; 
  height: 10rem;
}

label {
  display: block;
  font-weight: bold;
  padding-bottom: 2px;
  float: left;
  width: 100%;
}

.modal-label {
  top: 40px;
  position: relative;
}

.card-container.card {
  position: relative;
  padding: 40px;
  margin: auto;   
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 20px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.onError {
  border-color: red !important;
}

.navbar {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start  
}

.nav-stuff {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.nav-stuff-items {
  width: 100%;
  display: flex;
  flex-direction: column;  
}

.navbar-brand {
  position: relative;
  padding: 0px !important;
}

.navbar-toggler {
  margin-right: 10px;
}

.nav-item {
  font-size: 1.3rem;
  margin-right: 5px;
}

.message-zone {
  margin-top: 5px;
  width: 100%;
  height: 56px;
  background-color: #96a4d2;  
}

.language-selector {
  position: relative;
  float: right;
  right: 5px;
  top: 5px;
}

.language-selector button {
  background-color: #ffffff;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  top: 68px;
}

.left-menu-button-container {
  position: relative;
  color: #ffffff;
  top: -36px;
  z-index: 1101;
}

.left-menu-button {
  position: fixed;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  max-width: 240px;
}

/* General sidebar styles */
.bm-menu {
  background: #77b15f;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #ffffff;
  padding: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
  width: 115%;
}

.bm-item:hover {
  color: #d1d1d1;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.bm-item.active {
  background-color: #88b060;
  margin-left: -15px;
  margin-right: -15px;
}

.link {
  cursor: pointer;
}

.action {
  color: #88b060 !important;
}

.action:hover {
  color: #5b8036;
}

.btn-primary {
  color: #ffffff !important;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
}

.modal {
  z-index: 2001;
}

.modal-backdrop {
  z-index: 2000;
}

.modal-header {
  background-color: #88b060;
  color: #ffffff;
  margin: 2px;
  margin-bottom :10px;
  padding: 10px;
}

.form-check {
  padding-top: 1rem;
}

.input-icon > span { 
  position: relative;
  float: right;
  top: 42px;
  right: -34px;
} 

.input-icon { 
  width: 100%; 
  margin-top: -40px; 
} 

.icon { 
  padding: 10px;
  z-index:1;
} 

.input-field { 
  padding: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__navigation {
  top:10px !important;
}

.sp-is-invalid {
  border-color: #dc3545;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.btn-danger {
  color: #ffffff;
  background-color:#bb2d3b;
  border-color: #a52834;
}

.btn-secondary {
  color: #ffffff;
  background-color:#212529;
}

.loader-modal {
  margin: auto;
  width: 100px;
}

.accordion {
  margin-bottom: 5px;
}

.accordion-button {
  background-color: #96a4d2 !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button, .accordion-button:not(.collapsed) {
  color: #ffffff;
}

.accordion-button::after, .accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27white%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")
}

input[type=number].no-spinner::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number].no-spinner {
  -moz-appearance: textfield;
}

td.small_size {
  max-width: 5%;
  font-size: 0.8em;
}

td.slider_size {
  min-width: 60%;
}

td.step_mode_size {
  min-width: 15%;
  font-size: 0.8em;
}

.Toastify__toast {
  z-index: 999;
}

.tab-wrapper.sticky-top {
  z-index: 1080 !important;
  top: 190px;
  padding-top: 10px;
}

.em-08 {
  font-size: 0.8em;
}

.slider-reset {
  position: relative;
  top: 10px;
}

.bm-burger-button {
  z-index: 1081 !important;
}

.row-head {
  border: 1px solid #dee2e6;
  padding: 10px;
}

.col-head {
  padding: 10px;
  font-weight: bold;
}

.col-action {
  padding-top:12px;
}

.striped {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;    
  padding:5px;
}

.striped.empty {
  border-top: 1px solid #dee2e6;
}

.striped:nth-of-type(odd){
  background-color: #ffffff;
}

.striped:nth-of-type(even){
  background-color: #f2f2f2;
}

.react-tooltip {
  max-width:300px !important;
}

.recurrent-wording {
  border: 4px solid #dee2e6;
  margin: 4px;
  padding: 2px;
  height: 80%;
  overflow: auto;
}

.projection-button {
  margin-top: 12px;
}

.alarm {
  float:right;
}

.payment-info {
  padding: 20px;
  background-color: #f2f2f2;
}

.separator {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.invoices {
  text-align: left;
  max-height:300px;
  overflow: auto;
  background-color: #ffffff;
  padding: 10px;
}

.striped.empty {
  border-bottom: 1px solid #dee2e6;
}

.alert-danger {
  --bs-alert-color: #842029;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
}

.sp-form-group {
  padding-top: 20px;
  margin: auto;
  width: 50%;
}

.add-item {
  position: relative;
  top: -10px;
  left: -6px;
}

.text-small {
  text-align:left;
  font-size: 0.8em;
}

.border-top {
  border-top: 1px solid #dee2e6;
}

.tag-selector-row {
  display: inline-flex;
  cursor: pointer;
}

.tag-selector-col {
  padding: 4px;
}

.dropdown-toggle {
  margin-top: 10px;
}

.budget-chart {
  max-height: 500px !important;
}

@media (max-width: 768px) {

  .nav-item {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .navbar-toggler {
    margin-top: 10px;
    float: right;
  }

  .nav-stuff {
    width: 100%;
    display: block;
    flex-direction: none;
  }
  
  .nav-stuff-items {
    width: 100%;
    display: block;
    flex-direction: none;  
  }

  .footer-sm {
    margin-top:40px;
  }

  .sp-form-group {
    width: 100%;
  }

  .tab-wrapper.sticky-top {
    z-index: 1070 !important;
    top: 121px;
    padding-top: 10px;
  }

  .dropdown-toggle {
    margin-top: 20px;
  }

  .sp-container {
    min-height: 100vh;  
    margin-top: 15px;
    margin-left: 0 !important;
    padding: 25px;
    left: 20px;
    right:20px;
  }

  .sp-container-2 {
    top: 120px;
    min-height: 100vh;
    margin: auto;  
    padding: 40px;
  }
  
  .range-slider__wrap .range-slider__tooltip {
    z-index: 1069 !important
  }

}

@media (max-width: 991px) {                  
  .sp-list-head {
     display: none;
  }

  .budget-graph-div {
    overflow: scroll;
    width: 300px;
    height: 250px;
  }
}

@media (min-width: 768px) {

  .amount-projection {
    width: 150%;
    margin-left: -20px;
  }

  .budget-graph-div {
    overflow: scroll;
    width: 850px;
    height: 600px;
  }

}

.overflow-x-scroll {
  overflow-x: scroll;
}

.consent {
  position: relative;
  z-index:1102 !important;
}

.row-title {
  border: 1px solid #dee2e6;
  background-color: #96a4d2;
  color: white;
  padding: 4px;
  margin-top: 10px;
}

.reset-input {
  position: relative;
  top: -34px;
  right: -34px;
  float: right;
  font-weight: bold;
}

.react-colorful {
  left: 40px;
}

.tiny-accordion {
  margin:auto;
  width: 50%;
}

.radio-choices{
  text-align: left;
}